import Vue from "vue"
// import VueI18n from "vue-i18n"
import messages from "../../locales/translations"
import { createI18n } from 'vue-i18n'

// If js i18n environment is not configured, we default to english
if( ! window.__i18n ) {
    window.__i18n = {
        locale: 'en',
        available: ['en'],
        translations: {en: {}}
    }
}

// Avoid setting up translations that are not required
let filteredMessages = {}
__i18n.available.forEach(locale => {
    filteredMessages[locale] = messages[locale]
})

const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
    'da': {
        short: {
            year: 'numeric',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric'
        }
    }
}

// Vue.use(VueI18n)
const i18n = new createI18n({
    locale: __i18n ? __i18n.locale : 'en',
    messages: filteredMessages,
    fallbackLocale: 'en',
    datetimeFormats: dateTimeFormats,
    warnHtmlInMessage: 'off',
})

// Merge in custom js translations
__i18n.available.forEach(locale => {
    if( __i18n.translations[locale] ){
        i18n.global.mergeLocaleMessage(locale, __i18n.translations[locale])
    }
})

export { i18n }
export default i18n
